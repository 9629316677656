<style lang="scss" scoped>
  @import '@/styles/variables.scss';

  .proforma-table {
    &__wrapper {
    margin-bottom: 4rem;
    &__table {
      margin-top: 2.875rem;
      table {
        border-spacing: 0;
        width: 100%;
        word-break:break-word;
        tr {
          th {
            padding: 0.5rem;
            background-color: $corp-yellow;
            border: 1px solid #000000;
            color: #ffffff;
            height: 56px;
            margin: 0;
            text-transform: uppercase;
          }
          td {
            padding: 0.5rem;
            border: 1px solid $proform-border;
            color: $bg-btn-login;
            text-align: center;
            height: 3.5rem;
          }
          td.subtitle {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }
          td.item {
            width: 5rem;
          }
          td.ud {
            width: 4rem;
          }
          td.description {
            width: 17.0625rem;
          }
          td.value-meassure {
            width: 7.875rem;
          }
          td.hidden{
            border: none;
          }
          td.hidden-height {
            height: 1.125rem;
          }
        }
      }
    }
    &__summary {
      display: flex;
      justify-content: space-between;
      margin-top: 1.3125rem;
      &__signs {
        padding: 0;

      }
      &__box {
        border: 1px solid $proform-border;
        height: 12rem;
        padding: 1rem 0.05rem 0.75rem 1.5rem;
        width: 29.75rem;
        table {
          width: 100%;
          border-spacing: 0;
          tbody {
            tr {
              td {
                color: $bg-btn-login;
                font-weight: bold;
              }
              td.text {
                font-size: 1rem;
                text-transform: uppercase;
              }
              td.ammount {
                text-align: center;
                border: 1px solid $proform-border;
                width: 6.6875rem;
                height: 2.125rem;
              }
            }
          }
        }
      }
    }
    }
    &__btn {
      text-align: end;
    }
    &__edit-btn {
      justify-content: flex-end;
      display: flex;
      margin-top: 2.25rem;
      .send-proform {
        margin-right: 1.5rem;
      }
    }

  }

</style>

<template lang="pug">
  .proforma-table
    LoadingSpinner(v-show="isLoading")
    .proforma-table__wrapper(v-if="isLoaded")
      .proforma-table__wrapper__table
        table
          thead
            tr
              th(colspan=1) {{$t('item')}}
              th(colspan=2) {{$t('meassure')}}
              th(colspan=3) {{$t('contract')}}
              th(colspan=2) {{$t('origin total')}}
              th(colspan=2) {{$t('origin before')}}
              th(colspan=2) {{$t('on the month')}}
          tbody
            tr
              template(v-for="field in headers")
                td.subtitle {{$t(field)}}
            template(v-for="proformaMeasurements in rowsToShow")
              RowComponent(
                :isDisabled="isDisabled"
                :isEditComponent="isEditComponent"
                @emitProformaData="emitProformaData"
                :proformaMeasurements="proformaMeasurements"
              )
            tr
              td.hidden.hidden-height
            tr
              td.item.hidden
              td.ud.hidden
              td.description.hidden
              td.value-meassure.hidden
              td.value-meassure.hidden
              td.value-meassure {{ summatoryContractAmount.toFixed(2) || 0 }}
              td.value-meassure.hidden
              td.value-meassure {{summatoryTotalOriginValue.toFixed(2) || 0}}
              td.value-meassure.hidden
              td.value-meassure {{summatoryBeforeOrigin.toFixed(2) || 0}}
              td.value-meassure.hidden
              td.value-meassure {{summatoryOnMonth.toFixed(2) || 0}}
      .proforma-table__wrapper__summary
        .proforma-table__wrapper__summary__signs
          SignsSection(
            :showSubcontratorSign="true"
            :constructionManager="constructionManagerSign"
            :groupManager="groupManagerSign"
            :productionDirector="productionDirectorSign"
          )
        .proforma-table__wrapper__summary__box
          table
            tbody
              tr
                td.text {{($t('origin prevision'))}}
                td
                td.ammount {{summatoryTotalOriginValue.toFixed(2) || 0}} €
              tr
                td.text {{($t('before certification'))}}
                td
                td.ammount {{ '- ' + (summatoryBeforeOrigin.toFixed(2) || 0)}} €
              tr
                td.text {{($t('subcontrating month certification') )}}
                td
                td.ammount {{summatoryOnMonth.toFixed(2) || 0}}€
              tr
                td.text {{($t('taxes'))}}
                td.text {{newProformaObject.contract.retention || 0}}%
                td.ammount {{ '- ' + (summatoryRetention.toFixed(2) || 0)}} €
              tr
                td.text {{($t('total month'))}}
                td
                td.ammount {{summatoryTotal.toFixed(2) || 0}} €
    .proforma-table__btn
      v-btn(
              v-if="permissions.edit"
              color="primary"
              height="40"
              rounded
              width="170"
              :disabled="isLiquidationProforma"
              @click="checkIfTotalOriginBiggerBeforeOrigin"
              ) {{$t('save')}}
    .proforma-table__edit-btn(v-if="isEditComponent || isShowingActionAfterSigns")
      SendByEmail.send-proform(
        @emitSendEmail="emitSendEmail"
        :sendBill="false"
      )
      ProformaPrint(:idProforma="newProformaObject.id")
      ProformaWrongData(v-if="openWrongDataDialog" @emitHideDialog="emitHideDialog")
</template>

<script>

import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'

import { editProforma, generateProfomaPDF, sendByEmailProforma } from '@/services/Proforma'
import { getConstructionSubfoldersByDefaultFolderId } from '@/services/Construction'
import { uploadConstructionFile } from '@/services/Documents'
import { UserSign } from '@/shared/types/UserSigns'

import LoadingSpinner from '@/components/utils/Loading.vue'
import ProformaPrint from '@/components/Modals/ProformaPrint.vue'
import SendByEmail from '@/components/Modals/SendByEmail.vue'
import ProformaWrongData from '@/components/Modals/ProformaWrongData.vue'
import RowComponent from '@/components/Proforma/table/RowComponent.vue'
import SignsSection from '@/components/shared/SignsSection.vue'

const contractModule = createNamespacedHelpers('contracts')
const proformaModule = createNamespacedHelpers('proforma')
const globalModule = createNamespacedHelpers('global')

export default Vue.extend({

  name: 'ProformaTable',

  components: {
    LoadingSpinner,
    ProformaPrint,
    SendByEmail,
    ProformaWrongData,
    RowComponent,
    SignsSection
  },

  props: {

    isDisabled: {
      type: Boolean,
      required: false
    },

    provider: {
      type: {},
      required: false
    },

    isEditComponent: {
      type: Boolean,
      required: true
    },

    isShowingActionAfterSigns: {
      type: Boolean,
      required: false
    },

    permissions: {
      type: Object,
      required: true
    }

  },

  data () {
    return {
      summatoryContractAmount: 0,
      date: '',
      message: '',
      headers: [
        'cost',
        'ud',
        'ud description',
        'meassure',
        'price',
        'value',
        'meassure',
        'value',
        'meassure',
        'value',
        'meassure',
        'value'
      ],
      isLoaded: false,
      isLoading: false,
      objectToSave: [],
      openWrongDataDialog: false,
      payload: {
        liquidation: false,
        proformaMeasurements: []
      },
      proformData: {
        cif: '',
        concept: '',
        contractNumber: '',
        date: '',
        item: '',
        measurement: '',
        name: '',
        prevision: '',
        totalPrice: '',
        unit: '',
        unitPrice: ''
      },
      rowsToShow: {},
      summatoryTotalOriginValue: 0,
      summatoryBeforeOrigin: 0,
      summatoryRetention: 0,
      summatoryOnMonth: 0,
      summatoryTotal: 0,
      valid: false,
      constructionManagerSign: UserSign,
      groupManagerSign: UserSign,
      productionDirectorSign: UserSign
    }
  },

  computed: {

    ...contractModule.mapState([
      'idCurrentConstruction',
      'objectCurrentConstruction'
    ]),

    ...proformaModule.mapState([
      'isLiquidationProforma',
      'newProformaObject'
    ])

  },

  methods: {
    ...proformaModule.mapActions([
      'resetState'
    ]),

    calculateSummatoryContractAmount () {
      let summatory = 0

      if (!this.rowsToShow || this.rowsToShow.length === 0) {
        return
      }

      this.rowsToShow.forEach(measurement => {
        summatory += measurement.measurement?.totalPrice || 0
      })

      this.summatoryContractAmount = summatory

      this.$nextTick(() => {
        console.log(' Total ')
      })
    },

    checkMeasurementToModify (idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice) {
      if (this.objectToSave.some((object) => object.proformaMeasurementId === idMeasurement)) {
        this.objectToSave.map((object, idx) => {
          if (object.proformaMeasurementId === idMeasurement) {
            this.objectToSave[idx].totalUnitsPaid = Number(quantityTotalOrigin)
            this.objectToSave[idx].unitPrice = unitPrice
            this.objectToSave[idx].beforeOrigin = quantityBeforeOrigin
          }
        })
      } else this.pushToObjectToSave(idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice)
    },

    calculateOnMonth () {
      this.summatoryOnMonth = this.summatoryTotalOriginValue - this.summatoryBeforeOrigin
    },

    calculateRetention () {
      this.summatoryRetention = this.summatoryOnMonth * (this.newProformaObject.contract.retention / 100)
    },

    calculateSummatoryBeforeOrigin () {
      let summatory = 0
      this.rowsToShow.map(measurement => {
        summatory = summatory + (measurement.unitsPaidBefore * measurement.measurement.unitPrice)
      })

      this.summatoryBeforeOrigin = summatory
    },

    calculateSummatoryTotal () {
      this.summatoryTotal = this.summatoryOnMonth - this.summatoryRetention
    },
    calculeSummatoryTotalOriginAmount () {
      let summatory = 0
      let breakLoop = false

      this.rowsToShow.map(measurement => {
        this.objectToSave.map((object) => {
          if (measurement.measurement.id === object.proformaMeasurementId) {
            summatory = summatory + (object.totalUnitsPaid * object.unitPrice)
            breakLoop = true
          }
        })
        if (breakLoop === false) {
          summatory = summatory + (measurement.totalUnitsPaid * measurement.measurement.unitPrice)
        } else {
          breakLoop = false
        }
      })
      this.summatoryTotalOriginValue = summatory
    },

    calculateToDo () {
      this.calculeSummatoryTotalOriginAmount()
      this.calculateSummatoryBeforeOrigin()
      this.calculateOnMonth()
      this.calculateRetention()
      this.calculateSummatoryTotal()
      this.calculateSummatoryContractAmount()
    },

    checkIfTotalOriginBiggerBeforeOrigin () {
      if (this.provider.blocked) {
        this.$fire({
          title: 'Proveedor Bloqueado',
          text: `Todas las operaciones relacionadas con el proveedor ${this.provider.name} están bloqueadas`,
          type: 'error',
          confirmButtonColor: '#F7A549'
        })
      } else {
        this.editingProforma()
      }
    },

    async editingProforma () {
      try {
        this.isLoading = true
        this.isLoaded = false
        this.settingEditPayload()
        const response = await editProforma(this.newProformaObject.id, this.payload)
        if (response.status === 200) {
          this.$showSuccess('Proforma actualizada correctamente')
          this.toDoAfterSave()
        }
      } catch (error) {
        this.payload.proformaMeasurements = []
        this.$showError('Error al actualizar', 'Comprueba que los datos sean correctos')
        console.error(error)
      } finally {
        this.isLoaded = true
        this.isLoading = false
      }
    },

    emitHideDialog () {
      this.openWrongDataDialog = false
    },

    emitProformaData (idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice) {
      this.settingObjectToSave(idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice)
      this.calculeSummatoryTotalOriginAmount()
      this.calculateToDo()
    },

    async emitSendEmail (emailObject) {
      try {
        this.isLoading = true
        const date = new Date()
        const month = date.toLocaleString('default', { month: 'long' })
        emailObject.fileName = `Proforma ${month}`
        emailObject.idProforma = this.newProformaObject.id
        const email = await sendByEmailProforma(emailObject)
        if (email.statusCode === 200) {
          this.$showSuccess('Email enviado de manera exitosa')
        }
      } catch (error) {
        this.$showError('El email no ha podido enviarse')
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },

    async generatePDFifIsEdit (idProforma) {
      const docAdminFolderObjec = await getConstructionSubfoldersByDefaultFolderId(this.objectCurrentConstruction.rootFolder.id)
      const idDocAdminFolder = docAdminFolderObjec.folderChildren.filter(folder => folder.name === '1.DOC ADM')
      const xxxFolderObject = await getConstructionSubfoldersByDefaultFolderId(idDocAdminFolder[0].id)
      const idXXXFolder = xxxFolderObject.folderChildren.filter(folder => folder.name === 'xxx')
      const response = await generateProfomaPDF(idProforma)
      const blob = new Blob([response], { type: 'application/pdf' })
      const file = new FormData()
      file.append('file', blob)
      file.append('parentId', idXXXFolder[0].id)
      return file
    },

    pushToObjectToSave (idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice) {
      const payload = {
        proformaMeasurementId: idMeasurement,
        totalUnitsPaid: Number(quantityTotalOrigin),
        unitPrice: unitPrice,
        beforeOrigin: quantityBeforeOrigin
      }
      this.objectToSave.push(payload)
    },

    async savingProformaAsPDF () {
      const file64 = await this.generatePDFifIsEdit(this.newProformaObject.id)
      await uploadConstructionFile(file64)
    },

    settingEditPayload () {
      this.objectToSave.map(object => {
        this.payload.proformaMeasurements.push({
          proformaMeasurementId: object.proformaMeasurementId,
          totalUnitsPaid: object.totalUnitsPaid
        })
      })
    },

    settingObjectToSave (idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice) {
      if (this.objectToSave.length === 0) {
        this.pushToObjectToSave(idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice)
      } else {
        this.checkMeasurementToModify(idMeasurement, quantityTotalOrigin, quantityBeforeOrigin, unitPrice)
      }
    },

    settingPropsToRowComponen () {
      if (this.isEditComponent) {
        this.rowsToShow = this.getRowsToShow()
        this.calculateToDo()
      } else {
        this.rowsToShow = this.getRowsToShow()
      }
    },

    getRowsToShow () {
      const rowsToShow = []
      const lastProforma = this.newProformaObject.lastProforma

      if (this.newProformaObject.proformaMeasurement) {
        for (const proformaMeasurement of this.newProformaObject.proformaMeasurement) {
          const row = proformaMeasurement
          row.unitsPaidBefore = this.getUnitsPaidFromLastProforma(proformaMeasurement, lastProforma)

          rowsToShow.push(row)
        }
      }

      rowsToShow.sort(({ order: a }, { order: b }) => a - b)

      return rowsToShow
    },

    getUnitsPaidFromLastProforma (proformaMeasurement, lastProforma) {
      if (lastProforma) {
        return lastProforma.proformaMeasurement.find(pm => pm.order === proformaMeasurement.order)?.totalUnitsPaid || 0
      }
      return 0
    },

    getProformaMeasurementFromListByOrder (order, proformaMeasurements) {
      if (proformaMeasurements) {
        for (const proformaMeasurement of proformaMeasurements) {
          if (proformaMeasurement.order === order) {
            return proformaMeasurement
          }
        }
      }
      return undefined
    },

    async toDoAfterSave () {
      if (this.isEditComponent) {
        this.$emit('getProformaAgain')
        await this.savingProformaAsPDF()
      } else {
        this.$router.push(`/edit-proforma/${this.newProformaObject.id}`)
      }
    },

    initSignUsers () {
      this.constructionManagerSign = {
        signed: this.newProformaObject.signedByCM,
        signedDate: this.newProformaObject.signedByCMDate,
        user: this.newProformaObject.constructionManager
      }

      this.groupManagerSign = {
        signed: this.newProformaObject.signedByGM,
        signedDate: this.newProformaObject.signedByGMDate,
        user: this.newProformaObject.groupManager
      }

      this.productionDirectorSign = {
        signed: this.newProformaObject.signedByPD,
        signedDate: this.newProformaObject.signedByPDDate,
        user: this.newProformaObject.productionDirector
      }
    }
  },

  beforeMount () {
    this.initSignUsers()
  },

  mounted () {
    this.settingPropsToRowComponen()
    this.$nextTick(() => {
      this.calculateSummatoryContractAmount()
    })
    this.isLoaded = true
  }
})
</script>
